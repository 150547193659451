import { motion } from 'framer-motion';
import { map } from 'lodash';
import { IconButton, type IconButtonProps, type IconTypeWithLegacyFallback } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import {
  ArrowLeftIconM,
  ArrowRightIconM,
  ChevronLeftIconM,
  ChevronRightIconM,
} from '@carvertical/icons/react';
import { cn } from '@carvertical/utils/styling';
import { useCallback } from 'react';
import { SwipeableSections } from 'components/landings/common/SwipeableSections';
import { useFeaturesSection } from '../../hooks';
import { FeaturesTags } from './FeaturesTags';
import { V2FeatureCard } from './V2FeatureCard';
import { FeatureCard } from './FeatureCard';

type GetPartialIconButtonProps = {
  direction: 'next' | 'prev';
  v2Enabled: boolean;
};

type CarouselIconButtonProps = Pick<IconButtonProps, 'icon' | 'size' | 'variant'>;

const DRAG_THRESHOLD = 50;
const GAP = 48;

const FeaturesCarousel = () => {
  const { t } = useTranslation();
  const {
    features,
    highlightFeature,
    highlightedFeature,
    v2Enabled,
    trackFeatureTagClick,
    trackShowOtherFeature,
  } = useFeaturesSection();
  const featuresCount = Object.keys(features).length;
  const prevButtonDisabled = highlightedFeature === 0;
  const nextButtonDisabled = highlightedFeature === featuresCount - 1;
  const Card = v2Enabled ? V2FeatureCard : FeatureCard;

  const paginate = (direction: 'next' | 'prev') => {
    highlightFeature(
      direction === 'next'
        ? (highlightedFeature + 1) % featuresCount
        : (highlightedFeature - 1 + featuresCount) % featuresCount,
    );

    trackShowOtherFeature();
  };

  const changeStep = useCallback(
    (index: number) => {
      highlightFeature(index);
      trackShowOtherFeature();
    },
    [highlightFeature, trackShowOtherFeature],
  );

  const cards = map(features, (feature) =>
    feature ? (
      <div key={feature.id} className="w-full shrink-0">
        <Card {...feature} />
      </div>
    ) : null,
  );

  const steps = map(features, (feature) =>
    feature
      ? {
          Component: <Card {...feature} />,
        }
      : undefined,
  ).filter(Boolean);

  return (
    <div className="flex items-stretch">
      <div className="relative flex w-full flex-col items-center gap-6">
        <FeaturesTags
          className="max-lg:hidden"
          onTagClick={(index) => {
            highlightFeature(index);
            trackFeatureTagClick(index);
          }}
        />

        <div className="relative flex w-full flex-col items-center gap-6">
          <div
            className={cn(
              'absolute left-0 top-1/2 z-[1] -translate-x-2 -translate-y-1/2 md:-translate-x-1/2',
              prevButtonDisabled && v2Enabled && 'hidden',
              v2Enabled && 'max-sm:top-[310px]',
            )}
          >
            <IconButton
              {...getPartialIconButtonProps({ direction: 'prev', v2Enabled })}
              label={t('general.previousLabel')}
              disabled={prevButtonDisabled}
              onClick={() => paginate('prev')}
            />
          </div>

          <div
            className={cn(
              'absolute right-0 top-1/2 z-[1] -translate-y-1/2 translate-x-2 md:translate-x-1/2',
              nextButtonDisabled && v2Enabled && 'hidden',
              v2Enabled && 'max-sm:top-[310px]',
            )}
          >
            <IconButton
              {...getPartialIconButtonProps({ direction: 'next', v2Enabled })}
              label={t('general.nextLabel')}
              disabled={nextButtonDisabled}
              onClick={() => paginate('next')}
            />
          </div>

          <motion.div
            className="flex w-full cursor-grab gap-6 max-lg:!hidden"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.1}
            transition={{ type: 'spring', mass: 3, stiffness: 360, damping: 60 }}
            whileDrag={{ cursor: 'grabbing', opacity: 0.5 }}
            onDragEnd={(_, { offset: { x, y } }) => {
              if (Math.abs(x) < Math.abs(y)) {
                return;
              }

              trackShowOtherFeature();

              if (x > DRAG_THRESHOLD && highlightedFeature > 0) {
                paginate('prev');
              } else if (x < DRAG_THRESHOLD * -1 && highlightedFeature < featuresCount - 1) {
                paginate('next');
              }
            }}
            animate={{
              translateX: `calc(-${highlightedFeature * 100}% - ${GAP * highlightedFeature}px)`,
            }}
          >
            {cards}
          </motion.div>

          <SwipeableSections
            activeIndex={highlightedFeature}
            className="lg:hidden"
            fullWidth
            steps={steps}
            onChange={changeStep}
            triggeredFromOutside
          />
        </div>
      </div>
    </div>
  );
};

function getPartialIconButtonProps({
  direction,
  v2Enabled,
}: GetPartialIconButtonProps): CarouselIconButtonProps {
  const iconMap: Record<string, IconTypeWithLegacyFallback> = {
    v1: direction === 'next' ? ArrowRightIconM : ArrowLeftIconM,
    v2: direction === 'next' ? ChevronRightIconM : ChevronLeftIconM,
  };

  return {
    size: 'm',
    variant: v2Enabled ? 'transparent' : 'white',
    icon: iconMap[v2Enabled ? 'v2' : 'v1'],
  };
}

export { FeaturesCarousel };
