import { Trans, useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Button } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useRouteData } from 'context/RouteDataProvider';
import ArtDirectedImage from 'components/common/ArtDirectedImage';
import { isTranslationKeyValid } from 'modules/shared';
import { Link } from 'components/common/Link';
import type { Feature } from '../../types';
import { useFeaturesSection } from '../../hooks';
import { getFeatureIllustration } from '../../utils';

type V2FeatureCardProps = Feature;

const V2FeatureCard = ({ id, index }: V2FeatureCardProps) => {
  const { t } = useTranslation();
  const { locale } = useRouteData();
  const [fallback, setFallback] = useState(false);
  const { highlightedFeature, highlightFeature, trackViewSampleReportClick } = useFeaturesSection();
  const illustration = getFeatureIllustration({ id, locale, version: 'v2' });
  const illustrationSrc = fallback ? illustration.fallbackSrc : illustration.src;
  const descriptionKey = `features.${id}.v2.description`;

  const title = <h3 className="text-h-s">{t(`features.${id}.title`)}</h3>;

  return (
    <article
      className={cn(
        'grid w-full grid-cols-1 gap-3 rounded-m bg-blue-50 px-2 py-5 md:grid-cols-[1.1fr_1fr] md:rounded-xl md:p-6',
        index !== highlightedFeature && 'opacity-30',
      )}
    >
      <div className="md:hidden">{title}</div>

      <ArtDirectedImage
        className="pointer-events-none place-self-center object-contain"
        // @ts-expect-error TS(2739)
        srcSet={{
          forTabletPortraitUp: {
            src: illustrationSrc,
            width: 400,
            height: 285,
          },
          forDesktopUp: {
            src: illustrationSrc,
            width: 500,
            height: 360,
          },
        }}
        src={illustrationSrc}
        onError={() => setFallback(true)}
        quality={100}
        width={291}
        height={208}
      />

      <div className="flex flex-col gap-3 md:items-start md:self-center">
        <div className="max-md:hidden">{title}</div>

        {isTranslationKeyValid(descriptionKey) && (
          <p className="text-l">
            <Trans
              // @ts-expect-error TS(2339)
              t={t}
              i18nKey={descriptionKey}
              components={{
                2: (
                  <span className="flex h-3 content-['']">
                    <br />
                  </span>
                ),
              }}
            />
          </p>
        )}

        {index === highlightedFeature && (
          <Button
            as={Link}
            className="sm:self-start"
            href="/sample-report"
            onClick={trackViewSampleReportClick}
            variant="outlined"
            onFocus={() => {
              highlightFeature(index);
            }}
          >
            {t('general.viewSampleAction')}
          </Button>
        )}
      </div>
    </article>
  );
};

export { V2FeatureCard };
