import { useCallback, useEffect } from 'react';
import { useRouteData } from 'context/RouteDataProvider';
import { useGeoData, type FeatureId } from 'modules/shared';
import type { MarketId } from 'modules/market';
import { isExistingMarket } from 'utils/market';
import { useEventTracking } from 'modules/analytics';
import { getFeatures, getTags } from '../utils';
import {
  useFeaturesSectionList,
  useHighlightedFeatureIndex,
  useFeaturesSectionActions,
} from './useFeaturesSectionStore';

const FEATURE_KEYS: FeatureId[] = [
  'photos',
  'damage',
  'stolenVehicleCheck',
  'mileage',
  'specifications',
  'emissionTaxes',
  'valuation',
  'usage',
  'titleCheck',
  'safety',
  'registrationRestrictions',
  'financialRestrictions',
  'naturalDisasters',
];

const V2_FEATURES_SECTION_MARKETS: MarketId[] = [
  'britain',
  'france',
  'italy',
  'poland',
  'romania',
  'europe',
];

const useFeaturesSection = () => {
  const features = useFeaturesSectionList();
  const highlightedFeatureIndex = useHighlightedFeatureIndex();
  const geoData = useGeoData();
  const { market } = useRouteData();
  const { highlight, addFeatures } = useFeaturesSectionActions();
  const { track } = useEventTracking();
  const tags = getTags(features);

  const v2Market = isExistingMarket({
    market,
    markets: V2_FEATURES_SECTION_MARKETS,
  });

  useEffect(() => {
    addFeatures(
      getFeatures({ market, countryCode: geoData?.countryCode, featureKeys: FEATURE_KEYS }),
    );
  }, [geoData, market, addFeatures]);

  const highlightFeature = useCallback(
    (index: number) => {
      highlight(index);
    },
    [highlight],
  );

  const trackFeatureTagClick = useCallback(
    (index: number) => {
      if (!v2Market) {
        return;
      }

      track({ event: 'FeatureTagClicked', feature: tags[index].id });
    },
    [track, tags, v2Market],
  );

  const trackViewSampleReportClick = useCallback(() => {
    if (!v2Market) {
      return;
    }

    track({ event: 'FeatureViewSampleReportClicked' });
  }, [track, v2Market]);

  const trackShowOtherFeature = useCallback(() => {
    if (!v2Market) {
      return;
    }

    track({ event: 'FeatureShowOtherInitiated' });
  }, [track, v2Market]);

  return {
    features,
    tags,
    highlightedFeature: highlightedFeatureIndex,
    highlightFeature,
    v2Enabled: v2Market,
    trackFeatureTagClick,
    trackViewSampleReportClick,
    trackShowOtherFeature,
  };
};

export { useFeaturesSection };
