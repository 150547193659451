import { find, kebabCase, mapValues, pickBy, toLower, reduce } from 'lodash';
import { markets } from 'config-ts';
import type { Market } from 'modules/market';
import { EXCLUDED_MARKETS } from 'constants/markets';
import type { FeatureId } from 'modules/shared';
import type {
  PartialFeaturesRecord,
  Feature,
  FeatureIllustration,
  FeatureImageVersion,
} from './types';

type GetFeaturesParams = {
  countryCode?: string;
  featureKeys: FeatureId[];
  market: Market;
};

type GetFeatureIllustrationParams = {
  id: FeatureId;
  locale: string;
  version?: FeatureImageVersion;
};

const IMAGE_PATH = '/images/features/';
const COMPACT_IMAGE_PATH = '/images/features/compact/';
const V2_IMAGE_PATH = '/images/features/v2/';

const IMAGE_PATH_MAP: Record<FeatureImageVersion, string> = {
  original: IMAGE_PATH,
  compact: COMPACT_IMAGE_PATH,
  v2: V2_IMAGE_PATH,
};

const getTags = (features: PartialFeaturesRecord) => Object.values(features).filter(Boolean);

const prepareFeatures = (featureKeys: FeatureId[]) =>
  reduce(
    featureKeys,
    (result, key) => {
      const feature: Feature = {
        index: 0,
        id: key,
      };
      return { ...result, [key]: feature };
    },
    {},
  ) as Record<FeatureId, Feature>;

const getFeatures = ({
  countryCode,
  featureKeys,
  market,
}: GetFeaturesParams): PartialFeaturesRecord => {
  const marketFeatures = EXCLUDED_MARKETS.includes(market.id)
    ? find(markets, { countryCode })?.features
    : market.features;
  const features = marketFeatures ?? markets.britain.features;
  const filteredFeatures = pickBy(prepareFeatures(featureKeys), ({ id }) => features?.includes(id));
  let index = 0;

  return mapValues(filteredFeatures, (feature) => {
    const featureIndex = index;
    index += 1;

    return {
      ...feature,
      index: featureIndex,
    };
  });
};

const getFeatureIllustration = ({
  id,
  locale,
  version = 'original',
}: GetFeatureIllustrationParams): FeatureIllustration => {
  const imagePath = IMAGE_PATH_MAP[version];
  const image = kebabCase(id);

  return {
    src: `${imagePath}${image}-${toLower(locale)}.png`,
    fallbackSrc: `${imagePath}${image}-en.png`,
  };
};

export { getTags, getFeatures, getFeatureIllustration };
