import { Container, Stack, Text } from '@carvertical/ui';
import { cn } from '@carvertical/utils/styling';
import { useTranslation } from 'next-i18next';
import React from 'react';
import { isEmpty } from 'lodash';
import { Section } from 'components/landings/common/Section';
import { useFeaturesSection } from '../../hooks';
import { FeaturesCarousel } from './FeaturesCarousel';
import styles from './FeaturesSection.module.scss';

const FeaturesSection = React.forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation(['common', 'home', 'pricing']);
  const { features, v2Enabled } = useFeaturesSection();

  if (isEmpty(features)) {
    return null;
  }

  return (
    <Section>
      <Container>
        <Stack crossAxisAlign="stretch" gap={6} ref={ref}>
          <Stack gap={2} crossAxisAlign={{ mobileUp: 'flexStart', desktopUp: 'center' }}>
            <h2 className={cn('text-h-l', v2Enabled && 'text-center')}>
              {v2Enabled ? t('features.sectionTitle') : t('home:checksSection.title')}
            </h2>

            <Text className={styles.subtitle} variant="l">
              {v2Enabled
                ? t('features.sectionDescription')
                : t('pricing:disclosureSection.subtitle')}
            </Text>
          </Stack>

          <FeaturesCarousel />
        </Stack>
      </Container>
    </Section>
  );
});

export { FeaturesSection };
