import { Tag, TagGroup } from '@carvertical/ui';
import { useTranslation } from 'next-i18next';
import { cn } from '@carvertical/utils/styling';
import { useFeaturesSection } from '../../hooks';

type FeaturesTagsProps = {
  className?: string;
  onTagClick: (key: number) => void;
};

const FeaturesTags = ({ className, onTagClick }: FeaturesTagsProps) => {
  const { t } = useTranslation();
  const { tags, highlightedFeature } = useFeaturesSection();

  if (!tags.length) {
    return null;
  }

  return (
    <div className={cn('flex items-center', className)}>
      <TagGroup
        aria-label={t('features.informationTitle')}
        listClassName="flex flex-wrap justify-center"
        selectionMode="single"
        selectedKeys={[highlightedFeature]}
        onChange={(keys) => {
          if (typeof keys === 'object') {
            Object.values(keys).forEach((key) => {
              if (!key && typeof key !== 'number') {
                return;
              }

              onTagClick(key);
            });
          }
        }}
      >
        {tags.map(({ id, index }) => (
          <Tag key={index} id={index} size="l">
            {t(`features.${id}.title`)}
          </Tag>
        ))}
      </TagGroup>
    </div>
  );
};

export { FeaturesTags };
